<template>
  <div id="educator" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <div class="columns" v-else>
      <div class="column is-6 is-offset-3">
        <ValidationObserver ref="observer" v-slot="{ passes }">
          <form @submit.prevent="passes(submit)">
            <SdInput
              type="text"
              rules="required|email"
              label="Email"
              v-model="educator.user.email"
              placeholder="Enter a email"
            />

            <SdInput
              type="text"
              rules="required|min:2|max:20"
              label="Surname"
              v-model="educator.surname"
              placeholder="Enter a name"
            />

            <SdInput
              type="text"
              rules="required|min:2|max:20"
              label="Firstname"
              v-model="educator.firstname"
              placeholder="Enter a name"
            />

            <SdInput
              type="text"
              label="Othernames"
              v-model="educator.othernames"
              placeholder="Enter a name"
            />

            <SdSelect rules="required" label="Gender" v-model="educator.gender">
              <option v-for="gender in genders" :key="gender.value">
                {{ gender.text }}
              </option>
            </SdSelect>

            <b-field label="Date of Birth">
              <b-datepicker
                placeholder="Click to select..."
                icon="calendar-day"
                v-model="educator.dateOfBirth"
                :date-formatter="(date) => date.toDateString()"
              ></b-datepicker>
            </b-field>

            <SdInput
              type="text"
              :rules="{
                required: true,
                length: 13,
                regex: /234\d{10}$/,
              }"
              label="Phone"
              v-model="educator.phone"
              placeholder="Enter a name"
            />

            <SdSelect
              rules="required"
              label="Religion"
              v-model="educator.religion"
            >
              <option v-for="religion in religions" :key="religion.value">
                {{ religion.text }}
              </option>
            </SdSelect>

            <SdSelect
              rules="required"
              label="Qualification"
              v-model="educator.qualification"
            >
              <option
                v-for="qualification in qualifications"
                :key="qualification.value"
              >
                {{ qualification.text }}
              </option>
            </SdSelect>

            <button
              type="submit"
              class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
            >
              Submit
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchTerm } from '@/assets/js/app_info.js'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'educator',
  data() {
    return {
      schoolId: null,
      termId: null,
      pageTitle: 'Educator',
      genders: [
        { value: 'Female', text: 'Female' },
        { value: 'Male', text: 'Male' },
      ],
      qualifications: [
        { value: 'MSc', text: 'MSc' },
        { value: 'BSc', text: 'BSc' },
        { value: 'HND', text: 'HND' },
        { value: 'ND', text: 'ND' },
        { value: 'NCE', text: 'NCE' },
      ],
      religions: [
        { value: 'Christianity', text: 'Christianity' },
        { value: 'Islam', text: 'Islam' },
        { value: 'Others', text: 'Others' },
      ],
      skipQuery: true,
      educator: {
        id: null,
        surname: '',
        firstname: '',
        othernames: '',
        gender: null,
        phone: '',
        dateOfBirth: new Date(),
        qualification: null,
        religion: null,
        user: {
          email: '',
        },
      },
    }
  },
  apollo: {
    educator: {
      query: gql`
        query educatorId($id: ID!) {
          educator(id: $id) {
            id
            surname
            firstname
            othernames
            gender
            phone
            dateOfBirth
            qualification
            religion
            user {
              id
              email
            }
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
  },
  watch: {
    'educator.dateOfBirth'(newValue) {
      if (!newValue) return
      if (typeof newValue === 'string') {
        this.educator.dateOfBirth = new Date(newValue)
      }
    },
  },
  methods: {
    submit() {
      const surname = this.educator.surname.trim()
      const firstname = this.educator.firstname.trim()
      const othernames = this.educator.othernames.trim()
      const gender = this.educator.gender
      const phone = this.educator.phone
      const date_of_birth = this.educator.dateOfBirth
      const qualification = this.educator.qualification
      const religion = this.educator.religion
      const email = this.educator.user.email.trim()

      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createEducatorQuery(
                $school_id: Int!
                $surname: String!
                $firstname: String!
                $othernames: String!
                $gender: String!
                $phone: String!
                $date_of_birth: String!
                $qualification: String!
                $religion: String!
                $email: String!
                $termId: Int!
              ) {
                createEducator(
                  input: {
                    schoolId: $school_id
                    surname: $surname
                    firstname: $firstname
                    othernames: $othernames
                    gender: $gender
                    phone: $phone
                    dateOfBirth: $date_of_birth
                    qualification: $qualification
                    religion: $religion
                    email: $email
                    termId: $termId
                  }
                ) {
                  educator {
                    id
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              school_id: this.schoolId,
              surname: surname,
              firstname: firstname,
              othernames: othernames,
              gender: gender,
              phone: phone,
              date_of_birth: date_of_birth,
              qualification: qualification,
              religion: religion,
              email: email,
              termId: parseInt(this.termId),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createEducator.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(`/school/${this.schoolId}/educators`)
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateEducatorQuery(
                $id: Int!
                $surname: String!
                $firstname: String!
                $othernames: String!
                $gender: String!
                $phone: String!
                $date_of_birth: String!
                $qualification: String!
                $religion: String!
                $email: String!
              ) {
                updateEducator(
                  input: {
                    id: $id
                    surname: $surname
                    firstname: $firstname
                    othernames: $othernames
                    gender: $gender
                    phone: $phone
                    dateOfBirth: $date_of_birth
                    qualification: $qualification
                    religion: $religion
                    email: $email
                  }
                ) {
                  educator {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.educator.id),
              surname: surname,
              firstname: firstname,
              othernames: othernames,
              gender: gender,
              phone: phone,
              date_of_birth: date_of_birth,
              qualification: qualification,
              religion: religion,
              email: email,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateEducator.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(`/school/${this.schoolId}/educators`)
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      }
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    fetchTerm().then((term) => {
      this.termId = term.id
    })
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Educators',
        route: `/school/${this.schoolId}/educators`,
      },
    ])
  },
}
</script>

<style lang="scss" scoped></style>
